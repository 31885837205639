import requests from "../requests";

export default {
  list() {
    return requests.get("hires");
  },
  list_for_project(project) {
    return requests.get(`hires?project=${project.id}`);
  },
  get(id) {
    return requests.get(`hires/${id}`);
  },
  log(hire) {
    return requests.get(`hires/${hire.id}/log`);
  },
  update(o) {
    return requests.put(`hires/${o.id}`, o);
  },
  get_dates(hire) {
    return requests.get(`hires/${hire.id}/dates`);
  },
  update_dates(hire, o) {
    return requests.put(`hires/${hire.id}/dates`, o);
  },
  delete(obj) {
    return requests.delete(`hires/${obj.id}`);
  },
  compute_weight(obj) {
    return requests.get(`hires/${obj.id}/total/weight`);
  },
  overview(obj) {
    return requests.get(`hires/${obj.id}/overview`);
  },
  status_history(hire) {
    return requests.get(`hires/${hire.id}/status-history`);
  },
  create_quote(hire, o) {
    return requests.post(`hires/${hire.id}/quote`, o);
  },
  importHire(hire, o) {
    return requests.post(`hires/${hire.id}/import`, o);
  },
  reserve(hire) {
    return requests.post(`hires/${hire.id}/reservations`);
  },
  release(hire) {
    return requests.delete(`hires/${hire.id}/reservations`);
  },
  items: {
    list(hire) {
      return requests.get(`hires/${hire.id}/ng-items`);
    },
    truncate(hire) {
      return requests.delete(`hires/${hire.id}/ng-items`);
    },
    get(hire, id) {
      return requests.get(`hires/${hire.id}/ng-items/${id}`);
    },
    delete(hire, item) {
      return requests.delete(`hires/${hire.id}/ng-items/${item.id}`);
    },
    available(hire) {
      return requests.get(`hires/${hire.id}/ng-items/available`);
    },
    add(hire, id) {
      return requests.post(`hires/${hire.id}/ng-items/${id}`);
    },
    update(hire, item) {
      return requests.put(`hires/${hire.id}/ng-items/${item.id}`, item);
    },
    sync(hire, item) {
      return requests.post(`hires/${hire.id}/ng-items/${item.id}/sync`);
    },
    reserve(hire, item) {
      return requests.post(`hires/${hire.id}/ng-items/${item.id}/reserve`);
    },
    release(hire, item) {
      return requests.post(`hires/${hire.id}/ng-items/${item.id}/release`);
    },
    clear_rejection(hire, item) {
      return requests.delete(`hires/${hire.id}/ng-items/${item.id}/rejection`);
    },
    subhires: {
      list(hire, item) {
        return requests.get(`hires/${hire.id}/ng-items/${item.id}/subhires`);
      },
      list_eligible(hire, item, supplier) {
        return requests.get(
          `hires/${hire.id}/ng-items/${item.id}/subhires/${supplier.id}/eligible`
        );
      },
      add(hire, item, supplier, o) {
        return requests.post(
          `hires/${hire.id}/ng-items/${item.id}/subhires/${supplier.id}`,
          o
        );
      },
      remove(hire, item, supplier) {
        return requests.delete(
          `hires/${hire.id}/ng-items/${item.id}/subhires/${supplier.id}`
        );
      },
    },
  },
  validate(hire) {
    return requests.post(`hires/${hire.id}/validate`);
  },
  validation_data(hire) {
    return requests.get(`hires/${hire.id}/validate`);
  },
  update_validation_data(hire, obj) {
    return requests.put(`hires/${hire.id}/validate`, obj);
  },
  finalise(hire) {
    return requests.post(`hires/${hire.id}/finalise`);
  },
  unfinalise(hire) {
    return requests.delete(`hires/${hire.id}/finalise`);
  },
  cancel(hire) {
    return requests.post(`hires/${hire.id}/cancel`);
  },
  uncancel(hire) {
    return requests.delete(`hires/${hire.id}/cancel`);
  },
  complete(hire) {
    return requests.post(`hires/${hire.id}/complete`);
  },
  additionals: {
    list(hire) {
      return requests.get(`hires/${hire.id}/additionals`);
    },
    get(hire, id) {
      return requests.get(`hires/${hire.id}/additionals/${id}`);
    },
    create(hire, o) {
      return requests.post(`hires/${hire.id}/additionals`, o);
    },
    update(hire, o) {
      return requests.put(`hires/${hire.id}/additionals/${o.id}`, o);
    },
    delete(hire, o) {
      return requests.delete(`hires/${hire.id}/additionals/${o.id}`);
    },
  },
};
